// import * as firebase from "firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyDmdGUOPRinvz8zV3DNq9i-Sp88SWTN-tw",
  authDomain: "pkmganding-fab40.firebaseapp.com",
  databaseURL: "https://pkmganding-fab40.firebaseio.com",
  projectId: "pkmganding-fab40",
  storageBucket: "pkmganding-fab40.appspot.com",
  messagingSenderId: "116352388671"
};
export const firebaseApp = firebase.initializeApp(config);
