import React, { useState, useEffect, Suspense } from "react";
import "./App.css";
import "./style.css";
import { firebaseApp } from "./config/firebase";

const Public = React.lazy(() => import("./Public/Public"));
const Admin = React.lazy(() => import("./Admin/Admin"));

const App = () => {
  const [myUser, setUser] = useState();
  const loading = () => {
    return (
      <div className="loading">
        <div className="loading-content">
          <i className="fa fa-refresh fa-spin" />
        </div>
      </div>
    );
  };

  useEffect(() => {
    authListener();
    return () => {
      localStorage.removeItem("user");
      setUser(null);
    };
  }, []);
  const authListener = () => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      // console.log(user);
      if (user) {
        localStorage.setItem("user", user.uid);
        setUser(user);
        localStorage.setItem("myUser", myUser);
      } else {
        localStorage.removeItem("user");
        setUser(null);
      }
    });
  };

  return (
    <div className="App">
      {localStorage.getItem("user") ? (
        <Suspense fallback={loading()}>
          <Admin loading={loading} />
        </Suspense>
      ) : (
        <Suspense fallback={loading()}>
          <Public loading={loading} />
        </Suspense>
      )}
    </div>
  );
};

export default App;
